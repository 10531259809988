import "index.css"
import "syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"


// import PhotoSwipeLightbox from 'photoswipe/lightbox';
//
// const lightbox = new PhotoSwipeLightbox({
//   gallery: '#image',
//   children: 'a',
//   pswpModule: () => import('photoswipe')
// });
// lightbox.init();


console.info("Bridgetown is loaded!")
